module.exports = {
  typeCheck: {
    email: {
      empty: '이메일을 입력해주세요.',
      invalid: '올바르지 않은 이메일 형식입니다.',
    },
    password: {
      empty: '비밀번호를 입력해주세요.',
      invalid: '영문+숫자+특수문자 조합 8자 이상을 입력해 해주세요.',
    },
    name: {
      empty: '이름을 입력해주세요.',
      invalid: '올바르지 않은 형식입니다.',
    },
    contact: {
      empty: '연락처를 입력해주세요.',
      invalid: "'-' 를 포함한 숫자를 입력해주세요.",
    },
  },
  auth: {
    helper: '아직 이메일 주소가 없거나 로그인 정보가 생각나지 않는분은 support@mysojang.com으로 문의해주세요.',
    required: '로그인을 해주세요.',
    incorrect: '올바르지 않은 로그인 정보입니다.',
  },
  list: {
    // id -> route name & icon name 과 일치 해야함.
    drawerList: [
      { id: 'orders', category: '예약 현황' },
      { id: 'managers', category: '업체/소장 리스트' },
      { id: 'schedules', category: '작업 일정' },
      { id: 'events', category: '이벤트 리스트' },
      { id: 'products', category: '상품 리스트' },
      { id: 'parts', category: '비품 리스트' },
      { id: 'system', category: '보안 감사' },
    ],
  },
  table: {
    orders: [
      { id: 'reservationNumber', name: '예약번호' },
      { id: 'userName', name: '예약자명' },
      { id: 'categoryName', name: '서비스' },
      { id: 'createdAt', name: '예약일시' },
      { id: 'status', name: '진행 상황' },
      { id: 'requestStartAt', name: '방문 요청일시' },
      { id: 'scheduledAt', name: '방문 확정일시' },
      { id: 'technicians', name: '담당 소장' },
      { id: 'address1', name: '주소' },
      { id: 'etc', name: '기타' },
    ],
    managers: [
      { id: 'name', name: '성함' },
      { id: 'categoryName', name: '전문 분야' },
      { id: 'phoneNumber', name: '연락처' },
      { id: 'provider', name: '업체명' },
      { id: 'orderCnt', name: '총 수리 건수' },
      { id: 'lastScheduledTime', name: '마지막 출장일' },
    ],
    systemLog: [
      { id: 'date', name: '일시' },
      { id: 'status', name: '변경된 상태' },
      { id: 'manager', name: '관리자' },
      { id: 'contents', name: '이벤트 내용' },
    ],
    events: [
      { id: 'id', name: '번호' },
      { id: 'eventName', name: '이벤트명' },
      { id: 'period', name: '진행기간' },
      { id: 'eventStatus', name: '상태' },
      { id: 'createdTime', name: '등록일시' },
    ],
    technicians: [
      { id: 'id', name: '번호' },
      { id: 'name', name: '이름' },
      { id: 'company', name: '소속' },
      { id: 'phoneNumber', name: '연락처' },
      { id: 'specialties', name: '전문분야' },
      { id: 'note', name: '노트' },
      { id: 'event', name: '' },
    ],
  },
  select: {
    eventStatus: [
      { id: 'reserve', name: '진행예정' },
      { id: 'onGoing', name: '진행중' },
      { id: 'done', name: '종료' },
      { id: 'total', name: '전체' },
    ],
    pagenation: [
      { id: 1, name: 10 },
      { id: 2, name: 20 },
    ],
    period: [
      { id: 'month', name: '최근한달' },
      { id: 'week', name: '최근한주' },
      { id: 'total', name: '전체' },
    ],
    statusType: [{ id: 'status', name: '진행 상황', type: 'statusType' }],
    dateType: [
      { id: 'created_at', name: '예약일', type: 'dateType' },
      { id: 'request_start_at', name: '방문 요청일', type: 'dateType' },
      { id: 'schedules.start_at', name: '방문 확정일', type: 'dateType' },
    ],
    sort: [
      { id: 'ASC', name: '오름차순', type: 'sort' },
      { id: 'DESC', name: '내림차순', type: 'sort' },
    ],
    orderCode: [
      { id: 'requested', name: '예약접수', type: 'orderCode' },
      { id: 'confirmed', name: '예약확정', type: 'orderCode' },
      { id: 'ready', name: '매칭완료', type: 'orderCode' },
      { id: 'completed', name: '수리완료', type: 'orderCode' },
      { id: 'agreed', name: '청구서동의', type: 'orderCode' },
      { id: 'paid', name: '결제', type: 'orderCode' },
      { id: 'hold', name: '일시중지', type: 'orderCode' },
      { id: 'canceled', name: '취소', type: 'orderCode' },
      { id: 'manualPaid', name: '수동결제', type: 'orderCode' },
      { id: 'refunded', name: '환불', type: 'orderCode' },
      { id: 'etc', name: '기타', type: 'orderCode' },
      { id: 'all', name: '전체', type: 'orderCode' },
    ],
    order: [
      { id: 1, name: '1번째' },
      { id: 2, name: '2번째' },
      { id: 3, name: '3번째' },
      { id: 4, name: '4번째' },
      { id: 5, name: '5번째' },
      { id: 6, name: '6번째' },
      { id: 7, name: '7번째' },
      { id: 8, name: '8번째' },
      { id: 9, name: '9번째' },
      { id: 10, name: '10번째' },
    ],
  },
  // status tag
  orderStatus: {
    progress: {
      requested: { code: 'requested', name: '예약접수', action: 'requestModify' },
      confirmed: { code: 'confirmed', name: '예약확정', action: 'confirm' },
      ready: { code: 'ready', name: '매칭완료', action: 'matchModify' },
      completed: { code: 'completed', name: '수리완료', action: 'reportModify' },
      agreed: { code: 'agreed', name: '청구서동의', action: 'agree' },
      paid: { code: 'paid', name: '결제', action: 'pay' },
      hold: { code: 'hold', name: '일시중지', action: 'hold' },
      canceled: { code: 'canceled', name: '취소', action: 'cancel' },
      manualPaid: { code: 'manualPaid', name: '수동결제', action: 'manualPay' },
      refunded: { code: 'refunded', name: '환불', action: 'refund' },
      etc: { code: 'etc', name: '기타', action: 'etc' },
    },
    // front 에서 임의로 설정한 code
    zService: { code: 'zService', name: 'Z 서비스' },
    // getReady: { code: 'getReady', name: '출장 준비중' },
  },
  alert: {
    requested: {
      success: '서비스가 재접수 되었습니다.',
      error: '서비스 재접수에 실패했습니다. 다시 시도하여 주세요.',
    },
    completed: {
      success: '청구서가 전송되었습니다.',
      error: '청구서 전송에 실패했습니다. 다시 시도하여 주세요.',
    },
    refunded: {
      success: '환불 완료 되었습니다.',
      error: '환불 처리에 실패했습니다. 다시 시도하여 주세요.',
    },
    hold: {
      success: '서비스 일시중지 되었습니다.',
      error: '일시중지 처리에 실패했습니다. 다시 시도하여 주세요.',
    },
    canceled: {
      success: '서비스가 취소되었습니다.',
      error: '서비스 취소에 실패했습니다. 다시 시도하여 주세요.',
    },
    manualPaid: {
      success: '수동 결제가 완료되었습니다.',
      error: '수동 결제에 실패했습니다. 다시 시도하여 주세요.',
    },
    createData: {
      success: '성공적으로 생성되었습니다.',
      error: '생성에 실패했습니다. ',
    },
    fetchData: {
      error: '데이터를 가져오는데 실패했습니다.',
    },
    updateData: {
      success: '성공적으로 변경되었습니다.',
      error: '수정에 실패했습니다. ',
    },
    deleteData: {
      success: '성공적으로 삭제되었습니다.',
      error: '삭제에 실패했습니다. ',
    },
  },
  creditcardType: {
    personal: '개인',
    corporate: '법인',
  },
  radioButton: {
    eventPeriod: [
      { label: '무제한', value: 'unlimited' },
      { label: '기한선택', value: 'limited' },
    ],
    gender: [
      { label: '남성', value: true },
      { label: '여성', value: false },
    ],
    // 사유 모달
    canceled: [
      { label: '고객과 연락 불가', value: '0' },
      { label: '비품 문제로 수리진행 거부', value: '1' },
      { label: '비품 문제', value: '2' },
      { label: '변심', value: '3' },
      { label: '직접 입력', value: '4' },
    ],
    hold: [
      { label: '고객과 연락 불가', value: '0' },
      { label: '비품 문제로 수리진행 거부', value: '1' },
      { label: '비품 문제', value: '2' },
      { label: '공사 규모의 수리로 판단', value: '3' },
      { label: '수리 인력 부족', value: '4' },
      { label: '직접 입력', value: '5' },
    ],
    manualPaid: [
      { label: '카드 재결제', value: 'credit' },
      { label: '기타', value: 'etc' },
    ],
  },
};
